// if (module.hot) {
//   module.hot.accept();
// }

/*!
* Start Bootstrap - Grayscale v7.0.6 (https://startbootstrap.com/theme/grayscale)
* Copyright 2013-2023 Start Bootstrap
* Licensed under MIT (https://github.com/StartBootstrap/startbootstrap-grayscale/blob/master/LICENSE)
*/
//
// Scripts
// 

$(document).ready(function () { 
    setTimeout(function () { 
        $(".content").fadeIn(1000); 
    }, 1000); 
}); 

$(document.body).on('touchmove', rotateImg); // for mobile
$(window).on("scroll", function () {
    navbarShrink();
    rotateImg();
    backToTop();
});

function rotateImg(){
  var top = 40 + $(window).scrollTop()*0.3;
  $('.rotateZ').css('--var-rotate', top+'deg');

}

function navbarShrink(){
    const navbarCollapsible = document.body.querySelector('#navbar');
    const waBtn = document.body.querySelector('.wa__button');

    if (!navbarCollapsible) {
        return;
    }
    if (window.scrollY === 0) {
        navbarCollapsible.classList.remove('navbar-shrink')
        waBtn.classList.remove('show')
    } else {
        navbarCollapsible.classList.add('navbar-shrink')
        waBtn.classList.add('show')
    }
}

function backToTop(){
  const btt = document.body.querySelector('.btt_button');

  if (!btt){
    return;
  }
  if(window.scrollY<2000){
    btt.classList.remove('show')
  }else{
    btt.classList.add('show')
  }

  btt.addEventListener('click', () => {
    window.scrollTo(0,0);
  })
}


const heroScroll = document.querySelector('.hero__scroll');
const introduction = document.getElementById('introduction');

if(heroScroll){
  heroScroll.addEventListener('click', () => introduction.scrollIntoView({behavior: 'smooth', block: 'start'}));
}

// Navbar
let menuIcon = document.querySelector('.menuIcon');
let nav = document.querySelector('.overlay-menu');

menuIcon.addEventListener('click', () => {
    // if (nav.style.transform != 'translateX(0%)') {
    //     nav.style.transform = 'translateX(0%)';
    //     nav.style.transition = 'transform 0.2s ease-out';
    // } else { 
    //     nav.style.transform = 'translateX(-100%)';
    //     nav.style.transition = 'transform 0.2s ease-out';
    // }

    if (nav.className != 'overlay-menu toggle') {
        nav.className += ' toggle';
    } else {
        nav.className = 'overlay-menu';
    }
});


// Toggle Menu Icon ========================================
let toggleIcon = document.querySelector('.menuIcon');

toggleIcon.addEventListener('click', () => {
    if (toggleIcon.className != 'menuIcon toggle') {
        toggleIcon.className += ' toggle';
    } else {
        toggleIcon.className = 'menuIcon';
    }
});

let contactLink = document.querySelector('#contactLink');

contactLink.addEventListener('click', () => {
    if (toggleIcon.className != 'menuIcon toggle') {
        toggleIcon.className += ' toggle';
    } else {
        toggleIcon.className = 'menuIcon';
    }

    if (nav.className != 'overlay-menu toggle') {
      nav.className += ' toggle';
    } else {
        nav.className = 'overlay-menu';
    }
});



$(function() {
  
  var html = $('html');
  // Detections
  if (!("ontouchstart" in window)) {
    html.addClass("noTouch");
  }
  if ("ontouchstart" in window) {
    html.addClass("isTouch");
  }
  if ("ontouchstart" in window) {
    html.addClass("isTouch");
  }
  if (document.documentMode || /Edge/.test(navigator.userAgent)) {
    if (navigator.appVersion.indexOf("Trident") === -1) {
      html.addClass("isEDGE");
    } else {
      html.addClass("isIE isIE11");
    }
  }
  if (navigator.appVersion.indexOf("MSIE") !== -1) {
    html.addClass("isIE");
  }
  if (
    navigator.userAgent.indexOf("Safari") != -1 &&
    navigator.userAgent.indexOf("Chrome") == -1
  ) {
    html.addClass("isSafari");
  }

  // On Screen

  function isOnScreenn(el) {
    var elementTop = el.offset().top,
      elementBottom = elementTop + $(this).outerHeight(),
      viewportTop = $(window).scrollTop(),
      viewportBottom = viewportTop + $(window).height();
    return elementBottom > viewportTop && elementTop < viewportBottom;
  };

  function detection() {
    for (var i = 0; i < items.length; i++) {
      var el = $(items[i]);

      if (isOnScreenn(el)) {
        el.addClass("in-view");
      } else {
        el.removeClass("in-view");
      }
    }
  }

  var items = document.querySelectorAll(
    "*[data-animate-in], *[data-detect-viewport]"
  ),
    waiting = false,
    w = $(window);

  w.on("resize scroll", function() {
    if (waiting) {
      return;
    }
    waiting = true;
    detection();

    setTimeout(function() {
      waiting = false;
    }, 100);
  });

  $(document).ready(function() {
    setTimeout(function() {
      detection();
    }, 500);

    for (var i = 0; i < items.length; i++) {
      var d = 0,
        el = $(items[i]);
      if (items[i].getAttribute("data-animate-in-delay")) {
        d = items[i].getAttribute("data-animate-in-delay") / 1000 + "s";
      } else {
        d = 0;
      }
      el.css("transition-delay", d);
    }
  });
});


// Cards
$(function(){
  var cards = $('.cards-section').find('.item');

  cards.each(function(){
    var thisCard = $(this);

    var getTxt = thisCard.find('.content p').text();
    var realTxt = [getTxt]
    var textCol = [getTxt]
    var wordsCount = textCol[0].split(' ').length;
    var words = textCol[0].split(' ');
    var wordsLimit = 60;
    var limitCol;
    
    thisCard.find('.readmore h3').click(function(){
      if ( $(this).text() === "show more") {
          // thisCard.find('.content p').text(realTxt.join(' '));
          thisCard.find('.content').addClass('scroll');
          thisCard.find('.content p').show();
          thisCard.find('.content ul').show();
          $(this).text('show less');
          thisCard.addClass('showfull');
        } else {
          // thisCard.find('.content p').text(limitCol);
          $(this).text('show more');
          thisCard.removeClass('showfull');
          thisCard.find('.content p').hide();
          thisCard.find('.content ul').hide();
        }
    });
    
    // if (wordsLimit <= wordsCount) {
    //   words.splice(wordsLimit, wordsCount - wordsLimit);
    //   words = words.join(' ');
    //   thisCard.find('.content p').text(words + '...');
    //   limitCol = thisCard.find('.content p').text();
    // }
    
  })

  
});